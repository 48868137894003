import "../../libs/ads-core/src/components/GridItem/GridItem.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/GridItem/GridItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62Uy26DMBBF93yFl41kooTQPDf9FQecxAmP1JAmVcW/VyKq4jF0PEasmTkXzD2eZhels/OV/QSMpaq6ZuJ7yw6ZfOyCJpg+n362T49apaEu72FVC11v2ZfQb2H4nJhNjHENx2WRwuF3c7h6DQstBZwU5mT9mkzK7JYXfS9yMDdunY3Ou1za+Y9cpkqwKtFSFkwUKXvLVRHeVVqftmy1XF8fk5YFDoSxP8ocQufcOpzJrh3HTrB/p3llaitzCfeX3DpjO7Pz6f0bRmJlJe7h/p5b/8pM7P7L/nEjrrbijnD/yK0fbcb9X4j+NSP2ZsVmcD/jVl96YjtH27/UOJs2n0Wxo2oRTIq4o0WE6tEYSBVXkLfijp45q0kjIFVNIC/hjiri1aWtI1U+Qd6JOypKqzYNg1Q9h7ycO0pMqT4NQlAhjmcOFRYwecE9W01QYxgTUWUN+Wvu2XunOsOIiEoWP+WeauBqDcMhqinIV5DgVoam3jAsomIB+QUkuKWiqDkMSlB1E7lUjeGbxDDL3zKCuuNkICpvYN4GIvw9dKo9TgKiuoR5EiL8VcXVHwePXAVnmHeGCH+FaVfDODHIVVHCvBIi/CWnXB3jhDRB8wt6wdE/cA4AAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var gridArea = 'lkirljs';
export var gridAreaVars = {value:'gridArea',vars:{initial:'var(--lkirlja)',sm:'var(--lkirljb)',md:'var(--lkirljc)',lg:'var(--lkirljd)',xl:'var(--lkirlje)'},extractedVars:{initial:'--lkirlja',sm:'--lkirljb',md:'--lkirljc',lg:'--lkirljd',xl:'--lkirlje'}};
export var gridColumnEnd = 'lkirlju';
export var gridColumnEndVars = {value:'gridColumnEnd',vars:{initial:'var(--lkirljk)',sm:'var(--lkirljl)',md:'var(--lkirljm)',lg:'var(--lkirljn)',xl:'var(--lkirljo)'},extractedVars:{initial:'--lkirljk',sm:'--lkirljl',md:'--lkirljm',lg:'--lkirljn',xl:'--lkirljo'}};
export var gridColumnStart = 'lkirljt';
export var gridColumnStartVars = {value:'gridColumnStart',vars:{initial:'var(--lkirljf)',sm:'var(--lkirljg)',md:'var(--lkirljh)',lg:'var(--lkirlji)',xl:'var(--lkirljj)'},extractedVars:{initial:'--lkirljf',sm:'--lkirljg',md:'--lkirljh',lg:'--lkirlji',xl:'--lkirljj'}};
export var gridRowEnd = 'lkirljr';
export var gridRowEndVars = {value:'gridRowEnd',vars:{initial:'var(--lkirlj5)',sm:'var(--lkirlj6)',md:'var(--lkirlj7)',lg:'var(--lkirlj8)',xl:'var(--lkirlj9)'},extractedVars:{initial:'--lkirlj5',sm:'--lkirlj6',md:'--lkirlj7',lg:'--lkirlj8',xl:'--lkirlj9'}};
export var gridRowStart = 'lkirljq';
export var gridRowStartVars = {value:'gridRowStart',vars:{initial:'var(--lkirlj0)',sm:'var(--lkirlj1)',md:'var(--lkirlj2)',lg:'var(--lkirlj3)',xl:'var(--lkirlj4)'},extractedVars:{initial:'--lkirlj0',sm:'--lkirlj1',md:'--lkirlj2',lg:'--lkirlj3',xl:'--lkirlj4'}};
export var root = 'lkirljp';