import clsx from 'clsx';
import React from 'react';
import { Slot } from '@radix-ui/react-slot';

import { AsChild, InternalComponentProps } from '../Slot';
import { Variants, stackItem } from './StackItem.css';

type StackItemProps = {
  children: React.ReactNode;
} & Variants;

type StackItemComponentProps = StackItemProps &
  AsChild &
  InternalComponentProps;

export const StackItem = React.forwardRef<
  HTMLDivElement,
  StackItemComponentProps
>(
  (
    {
      children,
      grow,
      shrink,
      asChild,
      className = '',
      as = 'div',
      style = {},
      ...rest
    },
    ref
  ) => {
    const recipeClassnames = stackItem({ grow, shrink });
    const Component = asChild ? Slot : as;

    return (
      <Component
        ref={ref}
        className={clsx(recipeClassnames, className)}
        style={style}
        children={children}
        {...rest}
      />
    );
  }
);
