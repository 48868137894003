import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { AsChild, InternalComponentProps } from '../Slot';
import * as styles from './PageGrid.css';

type PageGridProps = {
  children: React.ReactNode;
} & styles.PageGridVariants;

type PageGridComponentProps = PageGridProps & AsChild & InternalComponentProps;

export const PageGrid = React.forwardRef<
  HTMLDivElement,
  PageGridComponentProps
>(
  (
    {
      children,
      className = '',
      asChild,
      as = 'div',
      style = {},
      alignY,
      alignX,
    },
    ref
  ) => {
    const classNames = styles.pageGrid({ alignX, alignY });
    const Component = asChild ? Slot : as;

    return (
      <Component
        className={clsx(classNames, className)}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
