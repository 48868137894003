import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import ReactIs from 'react-is';
import { InternalComponentProps } from '../Slot';
import { Stack } from '../Stack';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';

import * as styles from './NavLink.css';
type AnchorProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'onClick' | 'target' | 'aria-current'
>;

type NavLinkProps = InternalComponentProps &
  AnchorProps & {
    children: React.ReactNode;
    /** The icon before the link text */
    beforeIcon?: React.ReactNode;
    /** The icon after the link text */
    afterIcon?: React.ReactNode;
    /** Is the current page the link its pointing to? */
    isCurrent?: boolean;
    asChild?: boolean;
  } & styles.NavLinkVariants &
  ToneVariants;

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      children,
      href,
      beforeIcon,
      afterIcon,
      isCurrent,
      onClick,
      className = '',
      style = {},
      tone = 'onLight',
      size,
      asChild,
      target,
      'aria-current': ariaCurrent,
    },
    ref
  ) => {
    const recipeClassNames = styles.root({ size, tone });
    const text =
      asChild && ReactIs.isElement(children)
        ? (children.props.children as React.ReactNode)
        : children;

    const navLinkContent = (
      <>
        {beforeIcon}
        <span>{text}</span>
        {afterIcon}
      </>
    );

    const navLinkProps = {
      ...(href ? { href } : {}),
      ...(style ? { style } : {}),
      ...(target && { target }),
      ...(ariaCurrent || isCurrent !== undefined
        ? { 'aria-current': ariaCurrent || (isCurrent ? 'page' : undefined) }
        : {}),
      rel: target === '_blank' ? 'noopener noreferrer' : undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref: ref as any, // NOTE: Should we create a NavLinkButton?
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick: (e: any) => {
        if (onClick) onClick(e);
      },
      className: clsx(recipeClassNames, className),
    } as const;

    return (
      <Stack direction="row" gap={1} asChild alignY="start">
        {/* We always replace the children, so we can add our own markup and icons */}
        {ReactIs.isElement(children) && asChild ? (
          <ToneFocus tone={tone}>
            {React.cloneElement(children, {
              ...children.props,
              children: navLinkContent,
              ...navLinkProps,
              className: clsx(
                children.props.className,
                recipeClassNames,
                className
              ),
            })}
          </ToneFocus>
        ) : (
          <ToneFocus tone={tone}>
            <a {...navLinkProps}>{navLinkContent}</a>
          </ToneFocus>
        )}
      </Stack>
    );
  }
);
