import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Box } from '../Box';
import { CloseIcon, SearchIcon } from '../../icons';
import { IconButton } from '../IconButton';
import { Stack } from '../Stack';
import { Tag } from '../Tag';
import { ThemeContainer } from '../ThemeContainer';
import { ToneFocus, ToneVariants } from '../ToneFocusProvider';
import * as styles from './ImageDialog.css';

type DialogImageProps = {
  children: React.ReactElement;
} & ToneVariants;

type DialogProps = {
  children: React.ReactElement;
  isOpen: boolean;
  closeDialog: () => void;
};

export const FullScreenImage = (props: DialogProps) => {
  return (
    <Dialog.Root open={props.isOpen} onOpenChange={props.closeDialog}>
      <Dialog.Portal>
        <ThemeContainer>
          <Dialog.Content className={styles.dialogContent}>
            <Box className={styles.dialogButtonContainer}>
              <Dialog.Close
                asChild
                onClick={(event) => event.stopPropagation()}
              >
                <IconButton
                  icon={<CloseIcon />}
                  label="Sluit"
                  tone="onLight"
                  shape="brand"
                />
              </Dialog.Close>
            </Box>
            <Stack alignX="center" alignY="center" isFullHeight asChild>
              <Box
                paddingLeft={{ initial: 0, md: 16 }}
                paddingRight={{ initial: 0, md: 16 }}
                overflow="auto"
              >
                {props.children}
              </Box>
            </Stack>
          </Dialog.Content>
        </ThemeContainer>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const ImageDialog = (props: DialogImageProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <FullScreenImage isOpen={isOpen} closeDialog={() => setIsOpen(false)}>
        {props.children}
      </FullScreenImage>

      <ToneFocus tone={props.tone}>
        <button
          onClick={() => setIsOpen(true)}
          className={styles.imageDialogButton}
        >
          {props.children}
          <Tag className={styles.imageDialogInfo}>
            <Stack direction="row" gap={2} alignY="center">
              Vergroten
              <SearchIcon size="sm" />
            </Stack>
          </Tag>
        </button>
      </ToneFocus>
    </>
  );
};
