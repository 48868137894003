import React from 'react';
import clsx from 'clsx';
import { Box } from '../Box';
import { Text } from '../Text';
import { AsChild, InternalComponentProps } from '../Slot';
import { tag, TagVariants } from './Tag.css';

type TagProps = {
  children: React.ReactNode;
  backgroundColor?: TagVariants['backgroundColor'];
} & AsChild &
  InternalComponentProps;

export const Tag = ({
  children,
  className = '',
  asChild,
  style = {},
  backgroundColor = 'purple',
}: TagProps) => {
  return (
    <Box
      className={clsx(tag({ backgroundColor: backgroundColor }), className)}
      style={style}
      borderRadius="brandLg"
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={3}
      paddingRight={3}
      asChild
    >
      <Text size="label" asChild={asChild}>
        {children}
      </Text>
    </Box>
  );
};
