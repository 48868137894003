export const conditions = {
  initial: {},
  sm: { '@media': 'screen and (min-width: 768px)' },
  md: { '@media': 'screen and (min-width: 1024px)' },
  lg: { '@media': 'screen and (min-width: 1440px)' },
  xl: { '@media': 'screen and (min-width: 1920px)' },
} as const;

export const mq = {
  sm: conditions['sm']['@media'],
  md: conditions['md']['@media'],
  lg: conditions['lg']['@media'],
  xl: conditions['xl']['@media'],
};

export type BreakpointNames = keyof typeof conditions;
export type Breakpoints<T> = {
  [K in keyof typeof conditions]?: T;
};
export type ResponsiveValue<T> = T | Breakpoints<T>;
