import React from 'react';
import { mq } from '../global/breakpoints';

type BreakpointString = (typeof mq)[keyof typeof mq];

/**
 * Temporary hook?
 * @see https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 */
export function useMedia(mediaQueryString: BreakpointString) {
  const [matches, setMatches] = React.useState<boolean>();

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(!!mediaQueryList.matches);
    listener();
    mediaQueryList.addEventListener('change', listener);
    return () => mediaQueryList.removeEventListener('change', listener);
  }, [mediaQueryString]);

  return matches;
}
