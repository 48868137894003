import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement, isFragment } from 'react-is';
import { AsChild } from '../Slot';
import { InternalComponentProps } from '../Slot';
import { TextVariants, text } from './Text.css';

export type TextProps = { children: React.ReactNode } & TextVariants &
  AsChild &
  InternalComponentProps;

type DefaultMapper = Record<
  Required<TextVariants>['size'],
  keyof JSX.IntrinsicElements
>;

const defaultVariantElements: DefaultMapper = {
  label: 'span',
  description: 'span',
  paragraph: 'p',
};

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
  (
    {
      children,
      className = '',
      style,
      as,
      asChild,
      size = 'paragraph',
      align,
      color,
      weight,
    },
    ref
  ) => {
    const classNames = text({ size, align, color, weight });

    const Component =
      asChild && isElement(children) && !isFragment(children)
        ? Slot
        : as || defaultVariantElements[size];

    return (
      <Component
        className={clsx(classNames, className)}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
