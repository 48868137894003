import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement } from 'react-is';
import { isHeadingSize } from '../../global/utils';
import { AsChild, ExplicitAsChild, InternalComponentProps } from '../Slot';
import { HeadingVariants, heading } from './Heading.css';

export type Headers = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Responsive =
  | ({
      size: Headers;
      isResponsive?: HeadingVariants['isResponsive'];
    } & AsChild)
  | ({ size: HeadingVariants['size']; isResponsive: false } & ExplicitAsChild);

export type HeadingProps = {
  id?: string
  children: React.ReactNode;
  as?: Headers;
} & Omit<HeadingVariants, 'size'> &
  InternalComponentProps &
  Responsive;

export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  (
    {
      children,
      className = '',
      style,
      id,
      as,
      asChild,
      size = 'h1',
      color,
      align,
      fontWeight,
      isResponsive = true,
    },
    ref
  ) => {
    const ComponentMapper = () => {
      if (asChild && isElement(children)) {
        return Slot;
      }

      if (as) {
        return as;
      }

      if (isResponsive && typeof size === 'string' && isHeadingSize(size)) {
        return size;
      }

      return 'h1';
    };

    const Component = ComponentMapper();
    const classNames = heading({
      size,
      color,
      align,
      fontWeight,
      isResponsive,
    });

    return (
      <Component
        id={id}
        className={clsx(classNames, className)}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
