import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';
import clsx from 'clsx';
import ReactDOMServer from 'react-dom/server';
import { ReactElement } from 'react';
import React from 'react';
import { TextLink } from '../TextLink';
import { Heading } from '../Heading';
import { CheckmarkIcon, CloseIcon, DownloadIcon } from '../../icons/index';
import { Stack } from '../Stack';
import { Box } from '../Box';
import { MappedComponents, useAdsContext } from '../../providers';
import { ImageDialog } from '../ImageDialog';
import { Alert, isAlert } from '../Alert';
import { Video } from '../Video';
import * as styles from './RichText.css';

const isElement = (domNode: DOMNode): domNode is Element =>
  domNode.type === 'tag';

const options = (
  tone: styles.RichTextVariants['tone'],
  mapper: Required<RichTextMapper>
): HTMLReactParserOptions => ({
  trim: true,
  replace: (domNode) => {
    if (isElement(domNode)) {
      if (domNode.name === 'a') {
        const { href, ...props } = attributesToProps(domNode.attribs);
        if (!href) return null;

        const isPdfLink = domNode.attribs.href.endsWith('.pdf');
        const commonProps = { ...props, tone };
        const children = domToReact(domNode.children);

        return (
          <TextLink
            {...commonProps}
            afterIcon={isPdfLink ? <DownloadIcon /> : undefined}
            download={isPdfLink}
            asChild
          >
            {mapper.a({ children, href })}
          </TextLink>
        );
      }

      if (
        domNode.name === 'h1' ||
        domNode.name === 'h2' ||
        domNode.name === 'h3' ||
        domNode.name === 'h4'
      ) {
        return (
          <Heading size={domNode.name} color={tone}>
            {domToReact(domNode.children)}
          </Heading>
        );
      }

      if (domNode.name === 'ul' && domNode.attribs.class === 'list-crosses') {
        return (
          <ul>
            {domNode.children.filter(isElement).map((child, index) => {
              return (
                <Stack
                  key={`list-crosses--list-item-${index}`}
                  as="li"
                  alignY="start"
                  gap={3}
                  direction="row"
                >
                  <CloseIcon />
                  {domToReact(child.children)}
                </Stack>
              );
            })}
          </ul>
        );
      }

      if (
        domNode.name === 'ul' &&
        domNode.attribs.class === 'list-checkmarks'
      ) {
        return (
          <ul>
            {domNode.children.filter(isElement).map((child, index) => {
              return (
                <Stack
                  key={`list-checkmarks--list-item-${index}`}
                  as="li"
                  alignY="start"
                  gap={3}
                  direction="row"
                >
                  <CheckmarkIcon />
                  {domToReact(child.children)}
                </Stack>
              );
            })}
          </ul>
        );
      }

      if (domNode.name === 'table') {
        const tbody = domNode.children
          .filter(isElement)
          .find((child) => child.name === 'tbody');

        // It looks like there is no 'thead' coming from the CMS. The th elements are in the first tr of the tbody.
        let thead: Element[] | null = null;

        if (tbody) {
          thead = tbody.children
            .filter(isElement)[0]
            .children.filter(isElement);
        }

        if (tbody) {
          const rows = tbody.children
            .filter(isElement)
            .slice(1)
            .map((row, i) => {
              const cells = row.children.filter(isElement).map((cell, i) => {
                const correspondingHeader = thead?.length ? thead[i] : null;

                const headings = correspondingHeader
                  ? ReactDOMServer.renderToString(
                      domToReact(correspondingHeader.children) as ReactElement
                    ).replace(/(<([^>]+)>)/gi, '')
                  : '';

                return (
                  <td data-th={headings} role="cell" key={i}>
                    {domToReact(cell.children, {
                      replace: (nestedDomNode) => {
                        if (!isElement(nestedDomNode)) return;
                        const { href, ...props } = nestedDomNode.attribs;

                        const children = domToReact(nestedDomNode.children);
                        if (nestedDomNode.name === 'a') {
                          return (
                            <TextLink {...props} asChild>
                              {mapper.a({ children, href })}
                            </TextLink>
                          );
                        }
                      },
                    })}
                  </td>
                );
              });

              return (
                <tr className={styles.tableRow} role="row" key={i}>
                  {cells}
                </tr>
              );
            });

          return (
            <div className={styles.tableWrapper} role="region" tabIndex={0}>
              <Box asChild width="100%">
                <table className={styles.RichTextTable} role="table">
                  {thead ? (
                    <thead role="rowgroup">
                      {thead.map((tcell, i) => (
                        <Heading size="h6" key={i} asChild>
                          <tr role="row" className={styles.tableRowHeading}>
                            <th role="columnheader">
                              {domToReact(tcell.children)}
                            </th>
                          </tr>
                        </Heading>
                      ))}
                    </thead>
                  ) : null}
                  <tbody role="rowgroup">{rows}</tbody>
                </table>
              </Box>
            </div>
          );
        }
      }

      if (domNode.name === 'img') {
        const props = attributesToProps(domNode.attribs);
        const { style, src, alt } = props;

        // Sitecore sets the width and height on the style tag (wtf), so we need to extract those if they exist
        const componentProps = {
          width: parseInt(style?.width, 10) || undefined,
          height: parseInt(style?.height, 10) || undefined,
          alt,
          src,
        };

        // If no mapping has been given, we fall back to a simple <img />
        if (!mapper.img || !style) {
          // eslint-disable-next-line jsx-a11y/alt-text -- https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/793
          return (
            <ImageDialog tone={tone}>
              <img {...componentProps} />
            </ImageDialog>
          );
        }

        // If we're here that means that user has passed a callback as the img component
        return (
          <ImageDialog tone={tone}>
            {mapper.img({
              ...componentProps,
              width: parseInt(style.width, 10),
              height: parseInt(style.height, 10),
            })}
          </ImageDialog>
        );
      }

      if (isAlert(domNode.attribs.class)) {
        return (
          <Box paddingBottom={6}>
            <Alert variant={domNode.attribs.class}>
              {domToReact(domNode.children)}
            </Alert>
          </Box>
        );
      }

      if (
        domNode.attribs.class === 'youtube' ||
        domNode.attribs.class === 'vimeo'
      ) {
        const url = domToReact(domNode.children);

        if (typeof url === 'string') {
          return (
            <Box paddingBottom={8}>
              <Video url={url} />
            </Box>
          );
        }
      }
    }
  },
});

type RichTextMapper = MappedComponents;

export type RichTextProps = {
  children?: string;
  components?: RichTextMapper;
  editable?: boolean;
} & styles.RichTextVariants;

const defaultMapper: Required<RichTextMapper> = {
  a: ({ children, ...props }) => <a {...props}>{children}</a>,
  img: ({ alt, ...props }) => <img alt={alt} {...props} />,
};

export const RichText = React.forwardRef<HTMLDivElement, RichTextProps>(
  ({ children, tone, components: overrideComponents }, ref) => {
    // Get the initial components from the context, but always override them on a per-component basis
    const components = useAdsContext().components || overrideComponents;
    const mapper = { ...defaultMapper, ...components };

    if (!children) {
      return null;
    }

    const replacedCmsHtml = parse(
      sanitize(children, { ADD_ATTR: ['target'] }),
      options(tone, mapper)
    );

    const recipeClassnames = styles.richTextVariants({ tone });

    return (
      <div className={clsx(styles.richtext, recipeClassnames)} ref={ref}>
        {replacedCmsHtml}
      </div>
    );
  }
);
