import { forwardRef } from 'react';
import { IconVariants } from '../components/Icon/Icon.css';

import { IconWrapper } from '../components/Icon';
import { InternalComponentProps } from '../components/Slot';
import { ReactComponent as ArrowRightIconSvg } from './../../static/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIconSvg } from './../../static/icons/arrow-left.svg';
import { ReactComponent as ArrowDownIconSvg } from './../../static/icons/arrow-down.svg';
import { ReactComponent as ChevronDownIconSvg } from './../../static/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIconSvg } from './../../static/icons/chevron-up.svg';
import { ReactComponent as ChevronLeftIconSvg } from './../../static/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIconSvg } from './../../static/icons/chevron-right.svg';
import { ReactComponent as SearchIconSvg } from './../../static/icons/search.svg';
import { ReactComponent as FacebookIconSvg } from './../../static/icons/facebook.svg';
import { ReactComponent as LinkedinIconSvg } from './../../static/icons/linkedin.svg';
import { ReactComponent as XIconSvg } from './../../static/icons/logo-x.svg';
import { ReactComponent as InstagramIconSvg } from './../../static/icons/instagram.svg';
import { ReactComponent as PersonIconSvg } from './../../static/icons/person.svg';
import { ReactComponent as MenuIconSvg } from './../../static/icons/menu.svg';
import { ReactComponent as CloseIconSvg } from './../../static/icons/close.svg';
import { ReactComponent as InfoIconSvg } from './../../static/icons/info.svg';
import { ReactComponent as ExternalIconSvg } from './../../static/icons/external.svg';
import { ReactComponent as WarningIconSvg } from './../../static/icons/warning.svg';
import { ReactComponent as DownloadIconSvg } from './../../static/icons/download.svg';
import { ReactComponent as EditIconSvg } from './../../static/icons/edit.svg';
import { ReactComponent as CheckmarkIconSvg } from './../../static/icons/checkmark.svg';
import { ReactComponent as UploadIconSvg } from './../../static/icons/upload.svg';
import { ReactComponent as CalendarIconSvg } from './../../static/icons/calendar.svg';
import { ReactComponent as PinDropIconSvg } from './../../static/icons/pin-drop.svg';
import { ReactComponent as PersonPinIconSvg } from './../../static/icons/person-pin.svg';
import { ReactComponent as PlaySvg } from './../../static/icons/play.svg';
import { ReactComponent as LoadSvg } from './../../static/icons/load.svg';
import { ReactComponent as FlagIconSvg } from './../../static/icons/flag.svg';
import { ReactComponent as PhoneAndroidSvg } from './../../static/icons/phone-android.svg';
import { ReactComponent as DashboardSvg } from './../../static/icons/dashboard.svg';
import { ReactComponent as FrameSourceSvg } from './../../static/icons/frame-source.svg';
import { ReactComponent as SettingsSvg } from './../../static/icons/settings.svg';
import { ReactComponent as ResponsiveLayoutSvg } from './../../static/icons/responsive-layout.svg';
import { ReactComponent as RocketLaunchSvg } from './../../static/icons/rocket-launch.svg';
import { ReactComponent as RouteSvg } from './../../static/icons/route.svg';
import { ReactComponent as SpeedSvg } from './../../static/icons/speed.svg';
import { ReactComponent as TrackpadInputSvg } from './../../static/icons/trackpad-input.svg';
import { ReactComponent as VisibilitySvg } from './../../static/icons/visibility.svg';
import { ReactComponent as WysiwygSvg } from './../../static/icons/wysiwyg.svg';
import { ReactComponent as ChecklistSvg } from './../../static/icons/checklist.svg';
import { ReactComponent as NoElectricalConnectionIconSvg } from './../../static/icons/no-electrical-connection.svg';
import { ReactComponent as NoGasConnectionIconSvg } from './../../static/icons/no-gas-connection.svg';

import { ReactComponent as BeFlagSvg } from './../../static/flags/be.svg';
import { ReactComponent as NlFlagSvg } from './../../static/flags/nl.svg';
import { ReactComponent as DeFlagSvg } from './../../static/flags/de.svg';
import { ReactComponent as EuFlagSvg } from './../../static/flags/eu.svg';

// TODO: dynamically import them all, loop through them and export them all

type IconProps = IconVariants & InternalComponentProps;

export const ArrowRightIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowRightIconSvg} {...props} ref={ref} />;
  }
);

export const ArrowLeftIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowLeftIconSvg} {...props} ref={ref} />;
  }
);

export const ArrowDownIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowDownIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronUpIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronUpIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronRightIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronRightIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronLeftIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronLeftIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronDownIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronDownIconSvg} {...props} ref={ref} />;
  }
);

export const SearchIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SearchIconSvg} {...props} ref={ref} />;
  }
);

export const FacebookIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FacebookIconSvg} {...props} ref={ref} />;
  }
);

export const LinkedinIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={LinkedinIconSvg} {...props} ref={ref} />;
  }
);

export const XIcon = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={XIconSvg} {...props} ref={ref} />;
});

export const InstagramIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={InstagramIconSvg} {...props} ref={ref} />;
  }
);

export const PersonIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PersonIconSvg} {...props} ref={ref} />;
  }
);

export const PersonPinIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PersonPinIconSvg} {...props} ref={ref} />;
  }
);

export const MenuIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={MenuIconSvg} {...props} ref={ref} />;
  }
);

export const CloseIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CloseIconSvg} {...props} ref={ref} />;
  }
);

export const InfoIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={InfoIconSvg} {...props} ref={ref} />;
  }
);

export const ExternalIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ExternalIconSvg} {...props} ref={ref} />;
  }
);

export const WarningIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={WarningIconSvg} {...props} ref={ref} />;
  }
);

export const DownloadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={DownloadIconSvg} {...props} ref={ref} />;
  }
);

export const EditIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={EditIconSvg} {...props} ref={ref} />;
  }
);

export const CheckmarkIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CheckmarkIconSvg} {...props} ref={ref} />;
  }
);

export const UploadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={UploadIconSvg} {...props} ref={ref} />;
  }
);

export const CalendarIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CalendarIconSvg} {...props} ref={ref} />;
  }
);

export const PinDropIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PinDropIconSvg} {...props} ref={ref} />;
  }
);

export const PlayIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PlaySvg} {...props} ref={ref} />;
  }
);

export const LoadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={LoadSvg} {...props} ref={ref} />;
  }
);

export const FlagIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FlagIconSvg} {...props} ref={ref} />;
  }
);

export const NoElectricalConnectionIcon = forwardRef<
  HTMLOrSVGElement,
  IconProps
>((props, ref) => {
  return (
    <IconWrapper svg={NoElectricalConnectionIconSvg} {...props} ref={ref} />
  );
});

export const NoGasConnectionIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={NoGasConnectionIconSvg} {...props} ref={ref} />;
  }
);

export const DashboardIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={DashboardSvg} {...props} ref={ref} />;
  }
);
export const FrameSourceIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FrameSourceSvg} {...props} ref={ref} />;
  }
);
export const RocketLaunchIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={RocketLaunchSvg} {...props} ref={ref} />;
  }
);
export const RouteIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={RouteSvg} {...props} ref={ref} />;
  }
);
export const SpeedIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SpeedSvg} {...props} ref={ref} />;
  }
);
export const VisibilityIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={VisibilitySvg} {...props} ref={ref} />;
  }
);
export const WysiwygIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={WysiwygSvg} {...props} ref={ref} />;
  }
);

export const ResponsiveLayoutIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ResponsiveLayoutSvg} {...props} ref={ref} />;
  }
);

export const SettingsIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SettingsSvg} {...props} ref={ref} />;
  }
);

export const TrackpadInputIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={TrackpadInputSvg} {...props} ref={ref} />;
  }
);

export const ChecklistIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChecklistSvg} {...props} ref={ref} />;
  }
);

export const PhoneAndroidIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PhoneAndroidSvg} {...props} ref={ref} />;
  }
);

export const BeFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={BeFlagSvg} {...props} ref={ref} />;
});

export const NlFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={NlFlagSvg} {...props} ref={ref} />;
});

export const DeFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={DeFlagSvg} {...props} ref={ref} />;
});

export const EuFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={EuFlagSvg} {...props} ref={ref} />;
});
