import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { VisuallyHidden } from '../VisuallyHidden';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './IconButton.css';

type ButtonIconProps = {
  icon: React.ReactNode;
  label: string;
  className?: string;
} & styles.IconButtonVariants &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  (
    {
      icon,
      label,
      size = 'large',
      tone = 'onLight',
      shape = 'circle',
      variant = 'solid',
      className = '',
      ...rest
    },
    ref
  ) => {
    const classNames = styles.button({ size, tone, shape, variant });

    return (
      <ToneFocus tone={tone}>
        <button {...rest} className={clsx(classNames, className)} ref={ref}>
          {icon}
          <VisuallyHidden>{label}</VisuallyHidden>
        </button>
      </ToneFocus>
    );
  }
);
