import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import * as styles from './VisuallyHidden.css';

interface VisuallyHiddenProps {
  children: React.ReactNode;
  id?: string;
  'aria-live'?: 'polite' | 'assertive' | 'off';
  className?: string;
  asChild?: boolean;
}

export const VisuallyHidden = ({
  children,
  id,
  'aria-live': ariaLive,
  className = '',
  asChild = false,
  ...rest
}: VisuallyHiddenProps) => {
  const Component = asChild ? Slot : 'span';

  return (
    <Component
      aria-live={ariaLive}
      id={id}
      className={clsx(styles.container, className)}
      {...rest}
    >
      {children}
    </Component>
  );
};
