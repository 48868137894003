import React from 'react';
import clsx from 'clsx';
import { InternalComponentProps } from '../Slot';
import * as styles from './Icon.css';

export type IconWrapperProps = {
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} & styles.IconVariants &
  InternalComponentProps;

export const IconWrapper = React.forwardRef<HTMLOrSVGElement, IconWrapperProps>(
  ({ svg, size, color, className = '', style }, ref) => {
    const defaultIcon = null;
    if (!svg) return defaultIcon;

    const Component = svg;
    return (
      <Component
        // @ts-expect-error We need to type the svg prop so it accounts for a forwardRef
        ref={ref}
        className={clsx(styles.icon({ size, color }), className)}
        aria-hidden="true"
        style={style}
      />
    );
  }
);
