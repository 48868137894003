import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement } from 'react-is';
import { createDynamicVars } from '../../global/utils';
import { AsChild, InternalComponentProps } from '../Slot';
import { ResponsiveValue } from '../../global/breakpoints';
import * as boxStyles from './Box.css';

export type BoxProps = {
  id?: string;
  children?: React.ReactNode;
  width?: ResponsiveValue<
    `${number}px` | `${number}%` | `${number}vw` | 'auto'
  >;
  height?: ResponsiveValue<
    `${number}px` | `${number}%` | `${number}vh` | 'auto'
  >;
  top?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vw`>;
  left?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vw`>;
  right?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vw`>;
  bottom?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vw`>;
} & boxStyles.Variants;

type BoxComponentProps = BoxProps & AsChild & InternalComponentProps;

export const Box = React.forwardRef<HTMLDivElement, BoxComponentProps>(
  (props, ref) => {
    const {
      id,
      children,
      width,
      height,
      top,
      left,
      right,
      bottom,
      className = '',
      style = {},
      as = 'div',
      asChild,
      ...recipeProps
    } = props;

    const dynamicVariants = createDynamicVars([
      {
        value: width,
        vars: Object.values(boxStyles.widthVars.extractedVars),
      },
      {
        value: height,
        vars: Object.values(boxStyles.heightVars.extractedVars),
      },
      {
        value: top,
        vars: Object.values(boxStyles.topVars.extractedVars),
      },
      {
        value: left,
        vars: Object.values(boxStyles.leftVars.extractedVars),
      },
      {
        value: right,
        vars: Object.values(boxStyles.rightVars.extractedVars),
      },
      {
        value: bottom,
        vars: Object.values(boxStyles.bottomVars.extractedVars),
      },
    ]);

    const recipeClassnames = boxStyles.box(recipeProps);
    const Component = asChild && isElement(children) ? Slot : as;

    return (
      <Component
        id={id}
        className={clsx(
          {
            [boxStyles.width]: !!width,
            [boxStyles.height]: !!height,
            [boxStyles.top]: !!top,
            [boxStyles.left]: !!left,
            [boxStyles.right]: !!right,
            [boxStyles.bottom]: !!bottom,
          },
          recipeClassnames,
          className,
          boxStyles.root
        )}
        style={{ ...dynamicVariants, ...style }}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
