import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box } from '../Box';

import { GridItem } from '../GridItem';
import { PageGrid } from '../PageGrid';
import * as styles from './Video.css';

type VideoProps = {
  url: string;
  description: string;
};

type VideoSitecoreProps = {
  url: string;
  description: React.ReactElement;
};

export type VideoSharedProps = {
  url: VideoSitecoreProps['url'] | VideoProps['url'];
};

export const VideoComponent = (props: VideoProps) => (
  <VideoComponentView {...props} />
);

export const VideoComponentSitecore = (props: VideoSitecoreProps) => (
  <VideoComponentView {...props} />
);

export const VideoComponentView = ({ url }: VideoSharedProps) => {
  return (
    <PageGrid>
      <GridItem columnStart="1" columnEnd="-1">
        <Video url={url} />
      </GridItem>
    </PageGrid>
  );
};

export const Video = ({ url }: VideoSharedProps) => {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  return (
    <Box className={styles.videoWrapper}>
      <Box className={styles.innerWrapper}>
        {hasWindow && (
          <ReactPlayer
            width="100%"
            height="100%"
            url={url}
            config={{
              youtube: {
                playerVars: {
                  autoplay: 0,
                  controls: true,
                },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com',
                },
              },
              vimeo: {
                playerOptions: {
                  autoplay: 0,
                  controls: true,
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
