import React from 'react';
import { InternalComponentProps } from '../Slot';
import * as styles from './ToneFocusProvider.css';
type ToneProps = styles.ToneVariants & {
  children: React.ReactElement;
} & InternalComponentProps;

export const ToneFocus = React.forwardRef<unknown, ToneProps>(
  ({ children, tone, className = '', style = {} }, _) => {
    const focusClassNames = styles.focus({ tone });

    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        ...child.props,
        className: `${
          child.props.className || ''
        } ${className} ${focusClassNames}`.trim(),
        style: { ...child.props.style, ...style },
      });
    });
  }
);

export type ToneVariants = styles.ToneVariants;
